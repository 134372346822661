import './assets/styles/index.scss';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Main from "./pages";

function App() {
  return (
      <>
        <ToastContainer />
        <Main />
      </>
  );
}

export default App;
